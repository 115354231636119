import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

const about = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <Banner hero="about-banner" title="About" />
      <main id="content-about" className="container">
        <h1>What is Sentosa Restaurant?</h1>
        <p>
          We are a small homely local eatery that has been open since 2001,
          offering both dine-in as well as pick-ups and deliveries. We serve
          classic Chinese food, Vegetarian alternatives, and Malaysian-inspired
          special dishes.
        </p>
        <p>
          Take a look at our <Link to="/menu">menu</Link> or{" "}
          <Link to="/contact">call</Link> to place an order now.
        </p>
        <div className="swirls-container">
          <Img fluid={data.file.childImageSharp.fluid} alt="swirls" />
        </div>
      </main>
    </Layout>
  )
}

export default about

export const query = graphql`
  query {
    file(relativePath: { eq: "Swirls.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
